<template>
  <div class="CommentCreator">
    <RoomList :refresh="refresh" />
    <div class="page">
      <div class="header">
        <span class="title">{{ this.id ? "修改直播" : "創建直播" }}</span>
      </div>
      <div class="container" v-loading="loading">
        <el-scrollbar>
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="form"
            label-position="top"
            size="small"
          >
            <el-form-item label="直播標題" prop="title">
              <el-input v-model="ruleForm.title"></el-input>
            </el-form-item>
            <el-form-item label="Live ID" prop="live_id">
              <el-input v-model="ruleForm.live_id"></el-input>
            </el-form-item>
            <el-form-item label="Page Token" prop="token">
              <el-input v-model="ruleForm.token"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submit">提交</el-button>
            </el-form-item>
          </el-form>
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>
<script>
import RoomList from "../List";

export default {
  name: "RoomCreator",

  components: {
    RoomList,
  },

  watch: {
    "$route.path"() {
      this.init();
    },
  },

  created() {
    this.init();
  },

  data() {
    return {
      id: 0,
      loading: false,
      ruleForm: {
        title: "",
        live_id: "",
        token: "",
      },
      rules: {
        title: [
          { required: true, message: "請輸入直播標題", trigger: "blur" },
          {
            min: 3,
            max: 20,
            message: "長度在 3 到 20 個字串",
            trigger: "blur",
          },
        ],
        live_id: [
          { required: true, message: "请输入Live ID", trigger: "blur" },
        ],
        token: [
          { required: true, message: "请输入Page Token", trigger: "blur" },
        ],
      },
      refresh: "",
    };
  },

  methods: {
    init() {
      this.id = this.$route.params.id;

      if (this.id) {
        this.getRoom();
      } else {
        this.ruleForm = {
          title: "",
          live_id: "",
          token: "",
        };
      }
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    submit() {
      this.$refs["ruleForm"].validate(async (valid) => {
        if (!valid) {
          this.$message.error("填寫資料錯誤，請檢查");
          return;
        }

        if (this.id) {
          await this.$fetch("room/" + this.id, {
            method: "PUT",
            body: this.ruleForm,
          });
        } else {
          await this.$fetch("room", {
            method: "POST",
            body: this.ruleForm,
          });
        }

        this.refresh = Math.random().toString();
        this.$message.success("操作成功");
        this.$router.go(-1);
      });
    },

    async getRoom() {
      this.loading = true;

      const result = await this.$fetch("room/" + this.id);
      if (!result.isOk) {
        this.loading = false;
        this.$message.error(result.message);
        return;
      }

      const room = result.data;

      this.ruleForm = Object.assign(
        {},
        {
          title: room.title,
          live_id: room.live_id,
          token: room.token,
        }
      );

      this.loading = false;
    },
  },
};
</script>